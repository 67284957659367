import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "react-lazy-load-image-component/src/effects/blur.css"

// import mouseWheel from "../images/mouse-wheel.gif"
import whiteCurve from "../images/rounded-img.svg"

import Founder from "../images/founder.jpeg"

import competitiveRatesImg from "../images/about-us/comparative-rates.svg"

import profTeam from "../images/communication.svg"
import seamlessProc from "../images/process.svg"
import fastApproval from "../images/fast.svg"

import phoneIcon from "../images/about-us/phone.svg"
import emailIcon from "../images/about-us/mail.svg"
import locationIcon from "../images/about-us/address.svg"

import aimeAccredited from "../images/aime_email_badge.png"
// import Slide from "react-reveal/Slide"
// import Flip from "react-reveal/Flip"

import AboutHeroImg from "../images/about-hero-us.jpeg"

const AboutUs = () => (
  <Layout>
    <SEO title="About Us" description="About Us page" />
    {/* <div className="container-fluid hero-section">
      <div className="row">
        <div className="col-md-5 vertical-align d-none-mobile">
          <div className="brandstamp">
            <h1>About Us</h1>
            <p className=" pb-3 pt-3">
              At Mission Mortgage Colorado, we know that the right mortgage
              makes all the difference. It's the vehicle to bring your
              homeownership dreams to life, but also an important tool to manage
              debt and create wealth. Trust your financing to a team of experts
              that understands and applies this every day.
              <br />
            </p>
            <div className="button-div">
              <Link to="/contact" className="btn-global mr-3" type="button">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-7 about-hero-img vertical-align">
          <div className="brandstamp-mobile text-white">
            <h1>About Us</h1>
            <p className="mb-5">
              At Mission Mortgage Colorado, we know that the right mortgage
              makes all the difference. It's the vehicle to bring your
              homeownership dreams to life, but also an important tool to manage
              debt and create wealth. Trust your financing to a team of experts
              that understands and applies this every day.
              <br />
              <br />
            </p>
            <div className="button-div">
              <Link to="/contact" className="btn-global mr-3" type="button">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-lg-12 text-center scroll-down">
      <img className="mouse-wheel" src={mouseWheel} alt="Scroll Down" />
    </div> */}
    <div className="about-container">
      <div className="hero-about-container">
        <div className="container">
          <div className="row" style={{ padding: "10rem 0" }}>
            <div className="header-box col-lg-6">
              <h2 className="text-white">About Us</h2>
              <p className="pb-3 pt-3 text-white">
                At Mission Mortgage Colorado, we know that the right mortgage
                makes all the difference. It's the vehicle to bring your
                homeownership dreams to life, but also an important tool to
                manage debt and create wealth. Trust your financing to a team of
                experts that understands and applies this every day.
              </p>
              <div className="button-div">
                <Link to="/contact" className="btn-global mr-3" type="button">
                  Contact Us
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-img-box">
                <div className="overlay" />
                <img
                  className="img-fluid about-img"
                  src={AboutHeroImg}
                  alt="About Us"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="shape-container">
          <img src={whiteCurve} alt="White curve shape" />
        </div>
      </div>

      <div className="container">
        <div className="row flex-column-reverse flex-md-row align-items-center">
          <div className="col-lg-6">
            <h2 className="mb-5" style={{ color: "#1f1667" }}>
              About the Founder
            </h2>
            <p style={{ color: "#5f5f71" }}>
              Darlene Franklin has been providing home loan financing in the
              Denver metro area for over 20 years.
            </p>
            <p style={{ color: "#5f5f71" }}>
              She creates authentic connections with each of her clients by
              treating them like family. She listens to their goals, reviews
              their financing options with them, and helps determine what's best
              for each client. She reviews the pros and cons of each financing
              strategy with them and finds the one that is best for their
              situation.
            </p>
            <p style={{ color: "#5f5f71" }}>
              Darlene stays in touch with her clients throughout their
              milestones in life, and their changing goals. She started Mission
              Mortgage Colorado after realizing how much more she could offer
              her clients by working directly with wholesale lenders.
            </p>
            <p style={{ color: "#5f5f71" }}>
              Darlene's Mission is to provide even more value - more programs,
              better rates, lower fees, with all of the same personal touch -
              for every client, every mortgage, every time.
            </p>
          </div>
          <div className="col-lg-6 text-center mt-md-5">
            <div style={{ width: "100%" }} className="prof-pic-box">
              <img
                src={Founder}
                alt="Founder"
                style={{
                  borderRadius: "50%",
                  width: "50%",
                  boxShadow: "0 1rem 3rem rgb(226, 217, 255)",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="container my-5 py-3 apply-container">
          <div className="row align-items-center">
            <div className="col-lg-6 text-center">
              {/* <Flip left duration={1500}> */}
              <div className="step-img-wrapper">
                <img
                  src={aimeAccredited}
                  alt="Processing and Underwriting"
                  style={{ width: "12rem" }}
                />
              </div>
              {/* </Flip> */}
            </div>
            <div className="col-lg-6 text-center">
              {/* <Slide right duration={1500}> */}
              <h3 className="card-title my-3">
                Proud to be Locally Owned and Operated
              </h3>
              {/* </Slide> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid review-section reviews-section px-0">
        <div className="waves-top">
          <svg width="100%" height="200px" fill="none">
            <path
              fill="#FFF"
              d="
          M0 67
          C 273,183
            822,-40
            1920.00,106 

          V 359 
          H 0 
          V 67
          Z"
            >
              <animate
                repeatCount="indefinite"
                fill="#454599"
                attributeName="d"
                dur="15s"
                values="
            M0 77 
            C 473,283
              822,-40
              1920,116 

            V 359 
            H 0 
            V 67 
            Z; 

            M0 77 
            C 473,-40
              1222,283
              1920,136 

            V 359 
            H 0 
            V 67 
            Z; 

            M0 77 
            C 973,260
              1722,-53
              1920,120 

            V 359 
            H 0 
            V 67 
            Z; 

            M0 77 
            C 473,283
              822,-40
              1920,116 

            V 359 
            H 0 
            V 67 
            Z
            "
              ></animate>
            </path>
          </svg>
        </div>

        <div className="waves-bottom">
          <svg width="100%" height="200px" fill="none">
            <path
              fill="#FFF"
              d="
          M0 67
          C 273,183
            822,-40
            1920.00,106 

          V 359 
          H 0 
          V 67
          Z"
            >
              <animate
                repeatCount="indefinite"
                fill="#454599"
                attributeName="d"
                dur="15s"
                values="
            M0 77 
            C 473,283
              822,-40
              1920,116 

            V 359 
            H 0 
            V 67 
            Z; 

            M0 77 
            C 473,-40
              1222,283
              1920,136 

            V 359 
            H 0 
            V 67 
            Z; 

            M0 77 
            C 973,260
              1722,-53
              1920,120 

            V 359 
            H 0 
            V 67 
            Z; 

            M0 77 
            C 473,283
              822,-40
              1920,116 

            V 359 
            H 0 
            V 67 
            Z
            "
              ></animate>
            </path>
          </svg>
        </div>
        {/* <img className="top-wave" src={topWave} alt="Top Wave svg" />
            <img
              className="bottom-wave"
              src={bottomWave}
              alt="Bottom Wave svg"
            /> */}

        <div className="container">
          <div className="row align-items-center services-wrapper">
            <div className="col-lg-4">
              <h2 className="mb-5 text-white">Our Loan Services</h2>
              <p className="mb-5 text-white">
                At Mission Mortgage Colorado, we know that each client's story
                is unique, and we custom-tailor their financing accordingly.
                However, there are some things that remain the same with every
                loan: a seamless process, competitive rates, fast approvals, and
                first class service.
              </p>
              <a
                href="https://plus.preapp1003.com/Darlene-Franklin"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-global-inverted mr-3 text-white mb-5"
                type="button"
              >
                Apply Now
              </a>
            </div>
            <div className="col-lg-4 align-self-start p-0">
              <div className="card mt-0">
                <img
                  className="card-img-top"
                  src={seamlessProc}
                  alt="Seamless Process"
                  style={{ width: "7rem", margin: "0 auto" }}
                />
                <div className="card-body text-center">
                  <h5 style={{ fontWeight: "bold", marginTop: "0.5rem" }}>
                    Seamless Process
                  </h5>
                  <p>
                    Our process is simple and effective. You'll always know what
                    comes next.
                  </p>
                </div>
              </div>
              <div className="card mb-0">
                <img
                  className="card-img-top"
                  src={competitiveRatesImg}
                  alt="Competitive Rates"
                  style={{ width: "7rem", margin: "0 auto" }}
                />
                <div className="card-body text-center">
                  <h5 style={{ fontWeight: "bold", marginTop: "0.5rem" }}>
                    Competitive Rates
                  </h5>
                  <p>
                    We don't work for a bank, we work for YOU. We shop multiple
                    wholesale lenders directly to find you the best financing
                    possible. More programs. Better pricing. Lower Fees.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 p-0 align-self-end">
              <div>
                <div className="card">
                  <img
                    className="card-img-top"
                    src={fastApproval}
                    alt="Fast Approval"
                    style={{ width: "7rem", margin: "0 auto" }}
                  />
                  <div className="card-body text-center">
                    <h5 style={{ fontWeight: "bold", marginTop: "0.5rem" }}>
                      Fast Approvals
                    </h5>
                    <p>
                      Need a fast pre-approval? We've got you covered. And we
                      close your loan lightning fast too.
                    </p>
                  </div>
                </div>
                <div className="card mb-0">
                  <img
                    className="card-img-top"
                    src={profTeam}
                    alt="Professional Team"
                    style={{ width: "7rem", margin: "0 auto" }}
                  />
                  <div className="card-body text-center">
                    <h5 style={{ fontWeight: "bold", marginTop: "0.5rem" }}>
                      Professional Team
                    </h5>
                    <p>
                      With over 20 years of industry experience, our team truly
                      cares, and is ready to serve you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="apply-section">
        {/* <div className="protruded-gradient"></div> */}
        <div className="container">
          {/* <div className="apply-container"> */}
          <div className="row align-items-center">
            <div className="col-md-6 mx-auto">
              <div className="contact-info-box d-flex align-items-center mb-3">
                <img
                  src={phoneIcon}
                  alt="Contact Details"
                  className="contact-info-img mr-3"
                  style={{ width: "7%" }}
                />
                <div>
                  <p className="mb-0" style={{ color: "#5f5f71" }}>
                    Call or Text
                  </p>
                  <a href="tel:303-587-2389">303.587.2389</a>
                </div>
              </div>
              <div className="contact-info-box d-flex align-items-center mb-3">
                <img
                  src={emailIcon}
                  alt="Email Address"
                  className="contact-info-img mr-3"
                  style={{ width: "7%" }}
                />
                <a href="mailto:Darlene@missionmortgageco.com">
                  Darlene@missionmortgageco.com
                </a>
              </div>
              <div className="contact-info-box d-flex align-items-center">
                <img
                  src={locationIcon}
                  alt="Location"
                  className="contact-info-img mr-3"
                  style={{ width: "7%" }}
                />
                <div>
                  <p className="mb-0" style={{ color: "#5f5f71" }}>
                    8181 Arista Place, Suite 100
                  </p>
                  <p className="mb-0" style={{ color: "#5f5f71" }}>
                    Broomfield, CO 80021
                  </p>
                </div>
              </div>
              <div
                className="card apply-container mt-5 text-center"
                style={{ border: "none" }}
              >
                <div className="card-body">
                  <h5 className="card-title mt-0">Ready to discuss options?</h5>
                  <p className="card-text">
                    Give us a call, or contact us online
                  </p>
                  <Link
                    to="/contact"
                    className="btn-global mr-3 text-white"
                    type="button"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 mx-auto text-center">
              <iframe
                title="map-location"
                className="map-location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3060.5803667328596!2d-105.09067408444041!3d39.906026594463924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876b8bb7d7da67bf%3A0xcda61c48282edd11!2s8181%20Arista%20Pl%20%23100%2C%20Broomfield%2C%20CO%2080021%2C%20USA!5e0!3m2!1sen!2sph!4v1595833622048!5m2!1sen!2sph"
                width="100%"
                height="450"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                // tabIndex="0"
              ></iframe>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>

      <div className="apply-section">
        <div className="protruded-gradient"></div>
        <div className="container">
          <div className="apply-container">
            <div className="row align-items-center">
              <div className="col-md-8">
                <div>
                  <h2 className="mb-2 mt-3">
                    Let's get started
                    <br />
                  </h2>
                  <p className="pb-4">
                    Getting pre-approved can be quick and easy. Start the
                    process today!
                    <br />
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col">
                    <a
                      href="https://plus.preapp1003.com/Darlene-Franklin"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn-global w-100 mb-3"
                    >
                      Apply Now
                    </a>
                  </div>
                  <div className="col">
                    <Link
                      to="/loan-process"
                      className="btn-global-inverted w-100 text-center"
                      style={{ padding: "10px 20px" }}
                    >
                      Our Process
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default AboutUs
